@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(../../node_modules/react-multi-carousel/lib/styles.css);
html,
body,
:root {
  height: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  @apply bg-green-brand2;
}

html,
body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
}

body {
  /* @apply bg-background text-foreground; */
  font-feature-settings:
    'rlig' 1,
    'calt' 1;
}

@layer base {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    /* -------------------------------------------------- */
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: #bfb6c3;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 277 64% 33%;
    --primary-foreground: 210 40% 98%;
    /* --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%; */

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    .dark {
      --background: 224 71% 4%;
      --foreground: 213 31% 91%;

      --muted: 223 47% 11%;
      --muted-foreground: 215.4 16.3% 56.9%;

      --accent: 216 34% 17%;
      --accent-foreground: 210 40% 98%;

      --popover: 224 71% 4%;
      --popover-foreground: 215 20.2% 65.1%;

      --border: 216 34% 17%;
      --input: 216 34% 17%;

      --card: 224 71% 4%;
      --card-foreground: 213 31% 91%;

      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 1.2%;

      --secondary: 222.2 47.4% 11.2%;
      --secondary-foreground: 210 40% 98%;

      --destructive: 0 63% 31%;
      --destructive-foreground: 210 40% 98%;

      --ring: 216 34% 17%;

      --radius: 0.5rem;
    }
  }

  /* WebKit browsers */
  *::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  *::-webkit-scrollbar-thumb {
    @apply !bg-gray-brand2 !rounded-[100px];
  }

  *::-webkit-scrollbar-track {
    @apply !bg-gray-brand10 !rounded-[100px];
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #bfb6c3 #f0eef0;
  }

  *:hover::-webkit-scrollbar-thumb {
    @apply !bg-pink-brand-muted;
  }

  .full-screen-safe {
    width: 100vw;
    height: 100vh;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
      env(safe-area-inset-left);
    box-sizing: border-box;
  }

  .h-screen-safe {
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
  }

  .min-h-screen-safe {
    min-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
  }

  .w-screen-safe {
    width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
.border-dashed-long {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23DDD8DF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.react-multiple-carousel__arrow {
  @apply !min-w-9 !min-h-9 !backdrop-blur-md;
}
.react-multiple-carousel__arrow::before {
  @apply text-lg;
}

.fusion-radio input[type='radio']:checked + label svg > .checked {
  @apply transition ease-in-out duration-300 block;
}

/* Hide the first div when the radio is checked */
.fusion-radio input[type='radio']:checked + label svg > .unchecked {
  @apply transition ease-in-out duration-300 hidden;
}

label {
  @apply text-sm text-gray-brand6 font-medium;
}

.ProseMirror-focused {
  @apply focus-visible:outline-none;
}

.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  @apply float-left h-0 pointer-events-none text-gray-brand9;
}

.mention {
  @apply text-purple-brand bg-purple-brand2/15 py-0 px-1 rounded cursor-pointer hover:bg-purple-brand1;
}

.upload-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23BFB6C3FF' stroke-width='1' stroke-dasharray='9%2c 14' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
}

.popover-content-width-same-as-its-trigger {
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
}
